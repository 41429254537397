@font-face {
	src: url(./fonts/Avenir/Avenir-LT-35-Light.ttf) format('truetype');
	font-family: 'Avenir';
}
@font-face {
	src: url(./fonts/Lulo/Lulo-Clean-W01-One-Bold.ttf) format('truetype');
	font-family: 'Lulo';
}
@font-face {
	src: url(./fonts/Montserrat/static/Montserrat-Regular.ttf) format('truetype');
	font-family: 'Montserrat-Black';
}

$hoverMenuItems: #01e3c8;
html {
	scroll-behavior: smooth;
}
nav {
	font-family: 'Avenir';
}
main {
	display: flex;
	flex-direction: column;
	align-items: center;
}
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Avenir', 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
h1 {
	font-family: 'Lulo', 'Roboto';
}
h2 {
	font-family: 'Lulo', 'Roboto';
}
h3 {
	font-family: 'Lulo', 'Roboto';
}
h4 {
	font-family: 'Lulo', 'Roboto';
}
h5 {
	font-family: 'Lulo', 'Roboto';
}
p,
blockquote {
	max-width: 900px;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.stop-scrolling {
	height: 100%;
	overflow: hidden;
}
.social-media {
	width: 10rem;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	list-style: none;
}
.svg-icon {
	width: 1.2rem;
}
.svg-menu-icon {
	width: 1.2rem;
}
