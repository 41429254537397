@keyframes moving {
	0% {
		padding-bottom: 4rem;
	}
	50% {
		padding-bottom: 5rem;
	}
	100% {
		padding-bottom: 4rem;
	}
}

.acoustic-duo-group {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	gap: 10px;
	img {
		width: 100%;
		max-width: 320px;
	}
}
.music-page-paragraphs-section {
	width: 100%;
	max-width: 1200px;
	padding-top: 6rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 5%;
}
.paragraphs-section {
	width: 100%;
	padding-top: 2rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	p {
		max-width: 600px;
		text-align: center;
		padding-bottom: 2rem;
	}
}
.social-group {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 15px;
	margin-bottom: 6rem;
	a {
		color: black;
		font-size: larger;
	}
}

.on-mobile {
	display: none;
}
@media screen and (max-width: 800px) {
	.on-mobile {
		display: block;
	}
	.off-mobile {
		display: none;
	}
	.acoustic-duo-group {
		img {
			width: 100%;
			max-width: 900px;
		}
	}
	h1 {
		margin-top: 5rem;
	}
	p {
		margin: 4rem 0;
	}
}
