@font-face {
	src: url(../../fonts/Lulo/Lulo-Clean-W01-One-Bold.ttf) format('truetype');
	font-family: 'Lulo';
}
@font-face {
	src: url(../../fonts/Avenir/AvenirLTStd-Black.otf) format('otf');
	font-family: 'Avenir';
}
.home-page {
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	background-image: url('../../images/icons/eliz-baya.jpeg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
	padding-bottom: 3rem;
	text-align: center;
}
.title {
	font-size: 5rem;
	font-family: 'Lulo';
	color: white;
}
.sub-title {
	font-size: 1.5rem;
	font-family: 'Avenir';
	color: white;
	margin-top: -1rem;
}

@media screen and (max-width: 950px) {
	.home-page {
		height: 80vh;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		background-attachment: scroll;
	}
}
