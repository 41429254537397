.video-gallery-container {
	width: 100%;
	max-width: 1200px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 2rem;
	padding-bottom: 2rem;
	flex-wrap: wrap;
	iframe {
		margin: 0.5rem;
	}
}
