// $backgroundColor: white;
$backgroundColor: #01e3c8;
$hoverMenuItems: white;
// $hoverMenuItems: #01e3c8;

footer {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	flex-wrap: wrap;
	background-color: $backgroundColor;
	padding-top: 2rem;
	padding-bottom: 2rem;
	#contact {
		display: grid;
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-start;
		grid-template-columns: repeat(2, 1fr);
		gap: 20rem;
	}
	a:hover {
		color: white;
	}
}
.agent-container {
	display: flex;
	flex-direction: column;
	text-align: left;
	justify-content: center;
	align-items: center;
	h3 {
		margin-left: -4rem;
		margin-bottom: 0;
	}
}
.agent-list {
	list-style: none;
	display: flex;
	flex-direction: column;
	text-align: left;
	padding: 0;
	li {
		margin: 0.2rem;
		a {
			text-decoration: none;
			display: flex;
			align-items: center;
			color: black;
		}
	}
	.svg-icon {
		margin-right: 0.2rem;
	}
}
.social-media {
	// width: 10rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	list-style: none;
	a:hover {
		fill: $hoverMenuItems;
	}
}

@media screen and (max-width: 1024px) {
	footer {
		#contact {
			gap: 8rem;
		}
	}
}
@media screen and (max-width: 800px) {
	footer {
		#contact {
			grid-template-columns: repeat(1, 1fr);
			gap: 2rem;
		}
	}
}
