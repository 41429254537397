@font-face {
	src: url(../../fonts/Avenir/AvenirLTStd-Black.otf) format('otf');
	font-family: 'Avenir';
}
$hoverMenuItems: #01e3c8;
$menuFontFamily: 'Avenir';

.nav-mobile {
	display: none;
	position: fixed;
	background-color: white;
	font-size: 1rem;
	width: 100%;
	height: 4rem;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	font-family: $menuFontFamily;
}
.mobile-item {
	display: none;
}
.menu-mobile-items {
	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	flex-direction: column;
}
.burger-menu {
	padding-right: 2rem;
	padding-left: 5rem;
}
.nav-desktop {
	z-index: 2;
	position: fixed;
	background-color: white;
	font-size: 1rem;
	width: 100%;
	height: 4.5rem;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 18rem;
	font-family: $menuFontFamily;

	a {
		color: black;
		text-decoration: none;
	}
}
.menu-items {
	height: 100%;
	width: 30rem;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	list-style: none;
}
.social-media {
	width: 10rem;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	list-style: none;
}
.svg-icon {
	width: 1.2rem;
}
.svg-menu-icon {
	width: 1.2rem;
}

@media screen and (max-width: 800px) {
	nav {
		gap: 0;
	}
}
@media screen and (max-width: 830px) {
	.mobile-item {
		display: block;
	}
	.nav-mobile {
		display: flex;
	}
	.nav-desktop {
		display: none;
	}
}
