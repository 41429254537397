@keyframes moving {
	0% {
		padding-bottom: 4rem;
	}
	50% {
		padding-bottom: 5rem;
	}
	100% {
		padding-bottom: 4rem;
	}
}
.place-holder {
	width: 100%;
	height: 14.5rem;
}
.music-page-header-section {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	// margin-top: 4.5rem;
	width: 100%;
	height: 100vh;
	// max-height: 80vh;
	img {
		margin-top: 4.5rem;

		// position: relative;
		width: 100%;
		height: 100%;
	}
}
.arrow-btn {
	position: absolute;
	padding-bottom: 2rem;
	animation: moving 0.8s;
	animation-iteration-count: 999999;
	background: none;
	border: none;
}
.arrow-down-icon {
	padding: 5px;
	// border: solid 1px white;
	// border-radius: 50%;
}
.music-page-paragraphs-section {
	width: 100%;
	max-width: 1200px;
	padding-top: 8rem;
	padding-bottom: 5rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 5%;
	p {
		width: 85%;
	}
}
@media screen and (max-width: 800px) {
	.music-page-header-section {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
		// margin-top: 4.5rem;
		width: 100%;
		height: 50vh;
		// max-height: 80vh;
		img {
			margin-top: 4.5rem;

			// position: relative;
			width: 100%;
			height: 100%;
		}
	}
}
@media screen and (max-width: 800px) {
	p {
		text-align: justify;
	}
}
