.clients-venues-container {
	text-align: center;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	margin: 5rem 0;
}
.clients-venues-container-logos {
	display: grid;
	gap: 8;
	grid-template-columns: repeat(6, 1fr);
	max-width: 1200px;
	padding: 2rem 0;
	list-style: none;
	li {
		display: flex;
		justify-items: center;
		justify-content: center;
		align-items: center;
		margin-bottom: 0;
		width: 100%;
		margin: 20px;
	}
}

@media screen and (max-width: 1300px) {
	.clients-venues-container-logos {
		grid-template-columns: repeat(4, 1fr);
	}
}
@media screen and (max-width: 940px) {
	.clients-venues-container-logos {
		grid-template-columns: repeat(3, 1fr);
	}
}
@media screen and (max-width: 740px) {
	.clients-venues-container-logos {
		grid-template-columns: repeat(2, 1fr);
	}
}
@media screen and (max-width: 500px) {
	.clients-venues-container-logos {
		grid-template-columns: repeat(1, 1fr);
	}
}
