.photo-gallery-container {
	padding-top: 2rem;
	padding-bottom: 2rem;
	max-width: 1200px;
}
.image-container {
	position: relative;
	display: flex;
	justify-content: center;
	width: 100%;
	// background: transparent;
}
.modal-image {
	margin-top: 10rem;
	width: 100%;
	transform: scale(1.2);
}
