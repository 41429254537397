$backgroundColor: #01e3c8;

.about-section {
	width: 100%;
	max-width: 1200px;
	min-height: 70vh;
	padding-top: 2rem;
	padding-bottom: 2rem;
	display: grid;
	grid-template-columns: 1fr 1fr;
	// flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	text-align: center;
}
.image-container {
	width: 100%;
	img {
		width: 25rem;
	}
}
.text-container {
	width: 90%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	h2 {
		font-size: 2rem;
	}
}
@media screen and (max-width: 880px) {
	.about-section {
		grid-template-columns: 1fr;
	}
	.image-container {
		order: 1;
	}
	.text-container {
		order: 0;
		width: 100%;
		margin-bottom: 2rem;
		p {
			margin: 0 2rem;
		}
	}
}

@media screen and (min-width: 1280px) {
	.image-container {
		img {
			width: 28rem;
		}
	}
}
