$backgrounColor: #01e3c8;

.upcoming-events-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	background-color: $backgrounColor;
	padding: 5rem 0;
	h2 {
		text-align: center;
	}
}
.upcoming-evens-items-container {
	width: 100%;
	height: 100%;
	max-width: 600px;
}
.upcoming-evens-item {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	font-weight: bold;
	padding-left: 0;
	border-bottom: 1px solid rgba(0, 0, 0, 0.139);

	ul {
		list-style: none;
		padding-left: 0;
		li {
			margin: 0.7rem;
			margin-left: 0;
		}
	}
	.event-item-link {
		padding: 0.5rem;
		border: 2px solid white;
		text-decoration: none;
		color: white;
		font-weight: bold;
	}
}
@media screen and (max-width: 750px) {
	.upcoming-evens-item {
		margin: 0 1rem;
	}
}
@media screen and (max-width: 400px) {
	.upcoming-evens-item {
		margin: 0;

		text-align: center;
		flex-direction: column;
	}
	.event-item-link {
		margin-bottom: 1.5rem;
	}
}
