$backgroundColor: #01e3c8;
.reviews-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding-top: 2rem;
	padding-bottom: 2rem;
	grid-gap: 100;
}
.review-item {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	grid-template-areas: 'image image content content content ';
	align-items: center;
	justify-items: center;
	max-width: 900px;
	min-height: 50vh;
	gap: 2rem;

	blockquote {
		min-width: 300px;
		// position: absolute;
		grid-area: content;
		background-color: $backgroundColor;
		padding: 2rem;
		margin: 0;
	}
	img {
		grid-area: image;
		width: 100%;
		margin-bottom: 12rem;
	}
}
@media screen and (max-width: 830px) {
	.review-item {
		gap: 0rem;
		grid-template-columns: 1fr;
		grid-template-areas: 'image' 'content';
		padding: 2rem;

		img {
			margin-bottom: 0;
			width: 100%;
		}
	}
}
